/*  ===========contact page style   */


.cont-detail {
    padding-top: 5%;
    padding-bottom: 1%;
}

.cont-detail h5 {
    padding: 4% 0 2%;
    font-family: Libre Franklin;font-weight: 400;
}

.cont-detail img {
    padding: 0% 0 2%;
}

.cont-detail .heading-bg {
    padding: 11px 0 8px;
    background: #fff;
    max-width: 48%;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 2px #cde3cd;
    font-family: Libre Franklin;font-weight: 800;
}

.cont-detail .heading-bg span {
    color: #44a345;
}

.cont-detail h6 {
    padding: 2%;
    font-family: Libre Franklin;font-weight: 600;
}

.main-contact-container form {
    /*padding: 0 10% 6%;*/
}

.main-contact-container .form-control {
    line-height: 2;
}

/*.main-contact-container label {
    padding-top: 2%;
    padding-bottom: 2%;
    color: #44a345;
    font-family: Libre Franklin;font-weight: 600;
}
 .main-contact-container p{
padding: 3% 0;
font-family: Libre Franklin;font-weight: 400;
 }*/

.main-contact-container a {
    /*background: #efe711;
    padding: 10px 30px !important;
    color: #000;
    border-bottom: 2px solid #bcb60e !important;
    border-radius: 5px !important;
    margin-left: 15px;*/
    font-family: Libre Franklin;font-weight: 400;
}


/*  ========== server page style*/
/* purgecss ignore */
.no-webp .main-server-container {
    background: url(/images/server-background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.webp .main-server-container {
    background: url(/images/server-background.webp);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}


.main-server-container h5 {
    padding: 6% 0 20px;
}

.main-server-container .heading-connect {
    background: #fff;
    width: 25%;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 2px #cde3cd;
    font-family: Libre Franklin;font-weight: 800;
}

.main-server-container .inner-cont {
    padding: 3% 0;
}

.heading-connect h2 {
    padding: 6px 0;
    color: #44a345;
}

.main-server-container .circle {
    padding: 4% 44px 0 0;
}

.circle img {
    position: relative;
    left: 17%;
    bottom: 44px;
}

.main-server-container h1 {
    font-family: Libre Franklin;font-weight: 600;
    padding: 3% 0 2%;
}

.main-server-container hr {
    background: #44a345;
    width: 4%;
    margin: auto;
}

.main-server-container h6 {
    font-family: Libre Franklin;font-weight: 400;
    padding: 3% 0 0;
    line-height: 2
}

.main-server-container h6 a {
    color: #44a345
}

/*================*/


body.modal-open .background-container {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    filter: url("https://gist.githubusercontent.com/amitabhaghosh197/b7865b409e835b5a43b5/raw/1a255b551091924971e7dee8935fd38a7fdf7311/blur.svg#blur");
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}

.popUp-Container {

}

.popUp-Container h1 {
    padding: 19px 48px 0;
    font-family: Libre Franklin;font-weight: 800;
}

.popUp-Container h6 {
    color: #a4cea5;
    padding: 0;
    font-family: Libre Franklin;font-weight: 400;
}

.popUp-Container .modal-content {
    width: 80% !important;
    background: #44a345;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #bbcfbb;
    border: none;
}

.popUp-Container div#myModal {
    padding-left: 8%;
    display: block;
    width: 610px;
    text-align: center;
    top: 12%;
    margin: 0 auto;
}

.popUp-Container .modal-content .modal-body .txt {
    background: #8cd28d;
    border-radius: 35px;
}

.txt p {
    padding: 10px 0;
    font-family: Libre Franklin;font-weight: 400;
}

/* purgecss ignore */
.no-webp .popUp-Container .modal-content .modal-footer {
    background: url(/images/pop-bg.jpg);
    border: none;
    background-repeat: no-repeat;
}

.webp .popUp-Container .modal-content .modal-footer {
    background: url(/images/pop-bg.webp);
    border: none;
    background-repeat: no-repeat;
}

/* purgecss ignore */
.no-webp .popUp-Container .detail {
    padding: 11% 0 0;
    background: url(/images/pop-bg.jpg);
    border: none;
    background-repeat: no-repeat;
}

.webp .popUp-Container .detail {
    padding: 11% 0 0;
    background: url(/images/pop-bg.webp);
    border: none;
    background-repeat: no-repeat;
}

.popUp-Container .detail h6 {
    color: #000;
    font-family: Libre Franklin;font-weight: 400;
    padding-top: 3px;
    padding-bottom: 11px;
}

.popUp-Container .detail a {
    background: #efe711;
    padding: 10px 30px !important;
    color: #000;
    border-bottom: 2px solid #bcb60e !important;
    border-radius: 5px !important;
    margin-left: 15px;
    font-family: Libre Franklin;font-weight: 400;
}

.popUp-Container .detail p {
    color: #000;
    font-family: Libre Franklin;font-weight: 400;
    padding-top: 5%;
}


/*============================  Contact Us Page2*/
.contact-form-wrapper textarea {
    width: 100%;
    height: 130px;
    border-radius: 5px;
}

.contact-form-wrapper input[type=submit] {
    background: #efe711;
    margin-top: 33px;
    padding: 10px 0;
    color: #060606;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 600;
}
/* purgecss ignore */
.no-webp .address-map-container {
    background: url(/images/cont-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.webp .address-map-container {
    background: url(/images/cont-background.webp);
    background-size: cover;
    background-repeat: no-repeat;
}

.address-map-container .cntct-detail {
    padding: 75px 0;    
}

.cntct-detail {
    text-align: center;
}

.cntct-detail h6 {
    font-family: Libre Franklin;font-weight: 600;
}

.cntct-detail h1 {
    font-family: Libre Franklin;font-weight: 800;
    color: #fff;
}

.cntct-detail h5 {
    font-family: Libre Franklin;font-weight: 400;
}

.cntct-detail p {
    font-size: 14px;
    color: #fff;
}

.cntct-detail span {
    color: #44a345;
}

.cntct-detail h5 i.fa.fa-arrow-down {
    color: #44a345;
}

.address-map-container img {
    float: right;
    padding-top: 6%;

}

/* purgecss ignore */
.no-webp .contact-form-wrapper {
    background: url(/images/background-3.jpg);
    background-color: #707070;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 75px;
}

.contact-form-wrapper label {
    color: #fff;    
}

.faq li.q {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 95%;
    border: 1px #ddd solid;
    cursor: pointer;
}

.faq li.a {
    background: #dedede;
    display: none;
    color: #000;
}

.faq li.a a {
    color: #5db75d;
    font-weight: 600;
    text-decoration: underline;
}

.faq li {
    padding: 15px;
    text-align: left;
    list-style: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #d6d6d6;
    margin: 20px 0;
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

ul.faq {
    padding: 0;
    margin: 0 auto 1rem;
}

/*.forgotpass a {
    color: #333;
    margin: 15px 0 0 0;
    display: block;
    font-size: 16px;
}*/
.forgotpass {
    background-color: #f8f8f8;
    display: block;
    padding: 20px 35px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 20px #d4d4d4; */
    margin: 30px auto;
    /*max-width: 45%;*/
    max-width: 500px;
}

.forgotpass .emil {
    background-color: #f3f3f3;
    border: none;
    border-bottom: 2px solid #dddddd;
    padding: 12px 15px;
    color: #888;
    border-radius: 5px;
    margin-bottom: 17px;
    -webkit-appearance: none;
    display: block;
    width: 320px;
}

.forgotpass label {
    display: block;
    text-align: left;
    margin: 10px 0;
    font-size: 14px;
}

.forgotpass label > a {
    font-size: 14px;
}

.forgotpass .submit-tax {
    font-size: 18px;
    padding: 10px 0;
    width: 206px;
    display: block;
    margin: 0 auto;
    background: #efe711;
    border: none;
    color: #fff;
    border-radius: 5px;
}

.cont-detail .regpge {
    width: 50%;
}

a .btn .btn-default:hover {
    color: #efe711;
}

/* responsive */

@media only screen and (max-width: 1024px) {

    .pull-right {
        max-width: 100%;
    }

    .footer-widget {
        padding: 40px 0;
    }

    .footer-widget h1 {
        padding-left: 0;
    }

    textarea {
        width: 100%;
    }

    .address-map-container img {
        max-width: 100%;
    }

    .address-map-container .cntct-detail {
        padding: 25px 0;
    }

    .popUp-Container .detail {
        padding: 13% 0 0;
        background-size: cover;
    }
}

@media only screen and (max-width: 768px) {
    .cont-detail .heading-bg {
        width: 70%;
    }

}

@media only screen and (max-width: 767px) {
    .menu-container .desh {
        display: none;
    }

    .inner-header-wrapper h2, .faqs-container .faq-1 h2 {
        font-size: 26px;
    }

    .faqs-container .faq h2 img, .faqs-container .faq-1 h2 img {
        width: 45px;
        margin: 0 12px 0 0;
        float: left;
    }

    .faqs-container .faq h2 {
        font-size: 21px;
        line-height: 1;
    }

    .footer-widget {
        padding: 30px 0;
        text-align: center;
    }

    .footer-widget h1 {
        float: none;
        padding-left: 0;
        font-size: 25px;
    }

    .footer-widget small {
        font-size: 13px;
        bottom: 0;
        display: block;
    }

    footer {
        text-align: center;
    }

    footer p {
        font-size: 14px;
        padding-top: 0;
    }

    #myBtn {
        bottom: 10px;
        right: 2%;
        padding: 7px 12px;
    }

    .cont-detail .heading-bg {
        width: 100%;
    }

    .cont-detail .heading-bg h2 {
        font-size: 27px;
        margin: 0
    }

    .main-contact-container .form-control {
        margin: 0 0 10px 0;
    }

    textarea {
        width: 100%;
    }

    .address-map-container .cntct-detail {
        padding: 40px 0 0;
    }

    .popUp-Container .modal-content {
        width: 100% !important;
    }

    .popUp-Container div#myModal {
        padding-left: 0;
        width: 98%;
        top: 2%;
    }

    .popUp-Container h1 {
        padding: 14px 0 0;
    }

    .popUp-Container .detail {
        padding: 0 0 0;
        background-size: cover;

    }

    .txt p {
        padding: 2px 0;
    }

    .popUp-Container .detail h6 {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: -20px;
    }

    .forgotpass .emil {

        width: 100%;
    }
}

@media only screen and (max-width: 414px) {
    .popUp-Container div#myModal {
        padding-left: 0;
        width: 98%;
        top: 11%;
    }

    .popUp-Container .detail h6 {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0px;
    }

    .popUp-Container .detail {

        padding: 45px 0 0;
        background-size: cover;
    }
}

.rules {
    margin-top: 15px;
    list-style: none;
    padding: 0;
}

.rules li {
    color: #28a745;
}

.rules .is-invalid {
    color: #b94a48;
}

.rules span {
    margin-right: 5px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.rules .is-invalid span {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

.parsley-errors-list > li {
    display: none;
}

.parsley-errors-list > li:first-child {
    display: block;
}

.pos-rel {
    position: relative;
    padding-left: 35px;
}

.bs-callout-warning p.err {
    color: #B94A48;
}

.bs-callout-warning h4 {
    color: #B94A48;
}

.hint {
    color: #5c5c5c;
    margin-left: 5px;
    font-size: 14px;
    text-align: center;
}

/* RIOT FORM CSS */

/* FORM/CONTENT BOX */
.contentdiv {
    background-color: #f8f8f8;
    background-color: rgba(248, 248, 248, 0);
    border-radius: 10px;
    max-width: 450px;
}

.contentheader {
    text-align: left;
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
}

.contentheader span {
    right: 110px;
    top: -5px;
    position: absolute;
    color: #5db75d;
    font-size: 11px;
    font-weight: 400;
}

.contentlogo {
    float: right;
}

.contentheader h1 {
    color: #202020;
    font-size: 35px;
    font-weight: 400;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
}

.contentdiv h5 {
    color: #202020;
    font-size: 13px;
    font-weight: 400;
}

.content {
    text-align: left;
    border-radius: 5px;
    clear: both;
    max-width: 500px;
    margin: auto;
}

.contentfooter {
    text-align: center;
}

.contentfooter h5 {
    margin-top: 20px;
    font-size: 12px;
    color: #707070;
}

.contentfooter a {
    font-size: 14px;
    display: block;
    color: #33669a;
    font-weight: 500;
}

.contentfooter a:hover {
    color: #23486b;
}

/* FORM AND VALIDATION */
.inputdiv {
    display: block;
    padding: 10px 15px;
    background-color: #f3f3f3;
    border-bottom: 3px solid #dddddd;
    border-radius: 5px;
    user-select: none;
}

.inputdiv span {
    padding: 3px 0px;
    color: #23476b;
    display: inline-block;
    padding-left: 15px;
    float: right;
    border-left: 1px solid #dddddd;
    font-size: 16px;
}

.inputdiv input {
    display: inline-block;
    border: none;
    background-color: #f3f3f3;
    outline: 0;
    width: 70%;
}

@media only screen and (max-width: 767px) {
    .inputdiv input {
        display: inline-block;
        border: none;
        background-color: #f3f3f3;
        outline: 0;
        width: 70%;
    }
}


@media only screen and (max-width: 414px) {
    .inputdiv input {
        display: inline-block;
        border: none;
        background-color: #f3f3f3;
        outline: 0;
        width: 60%;
    }
}

@media only screen and (max-width: 345px) {
    .inputdiv input {
        display: inline-block;
        border: none;
        background-color: #f3f3f3;
        outline: 0;
        width: 50%;
    }
}

@media only screen and (max-width: 305px) {
    .inputdiv input {
        display: inline-block;
        border: none;
        background-color: #f3f3f3;
        outline: 0;
        width: 40%;
    }
}


.inputdiv input[type="radio"] {
    position: absolute;
    opacity: 0;
    left: -15px;
    z-index: 100;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.radio-fill {
    position: absolute;
    top: 5px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ccc;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.inputdiv:hover input ~ .radio-fill {
    background-color: #aaa;
}

/* When the radio button is checked, add a green background */
.inputdiv input:checked ~ .radio-fill {
    background-color: #5db75d;
}

.inputdiv input::-webkit-input-placeholder {
    color: #505050;
}

.confirm {
    display: none;
    transition: all .3s linear 0s;
}

.confirm ~ .parsley-errors-list {
    display: none;
}

.error {
    color: red;
    visibility: hidden;
    margin: 5px 0px;
}

.cemailerror, cpasserror {
    display: none;
}

.submitbtn
{
    background-color: #efe711;
    color:black;
    padding: 10px 30px;
    border-radius: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid #bcb60e;
    font-size: 24px;
    display: inline-block;
    margin:10px 0px;
    outline:0;
}
.submitbtn:hover
{
    color:#33669a !important;
    background-color: #efe711 !important;
}
.submitbtn:focus
{
    color:black !important;
    background-color: #efe711 !important;
}

/* SPLASH AND PROGRESS BAR */
.splashdiv {
    overflow: auto;
    background-color: #f8f8f8;
    display: inline-block;
    padding: 45px 30px;
    /*border-bottom:3px solid #dddddd;*/
    border-radius: 5px;
    padding-bottom: 70px;
    max-width: 450px;
}

.splashimg {
    /*float:left;*/
    text-align: center;
    width: auto;
    padding-left: 25px;
}

.splashtext {
    float: left;
    width: auto;
    text-align: left;
    padding-left: 35px;
}

.splashtext h2 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 0px;
}

.splashtext p {
    font-size: 18px;
    font-weight: 400;
}

#progressbar {
    height: 25px;
    position: relative;
    margin-top: 35px;
    max-width: 500px;
}

#progressbar svg {
    border-radius: 25px;
}

.progressbar-text {
    color: #fff !important;
    background-color: #5db75d;
    position: absolute;
    top: 30px;
    padding: 5px 0px !important;
    width: 60px !important;
    margin-top: 10px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: 'Libre Franklin', sans-serif !important;
    border-radius: 25px !important;

}

.progressbar-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #5db75d transparent;
    margin-top: 10px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 586px) {
    .splashdiv {
        text-align: center;
    }

    .splashimg {
        float: none;
        padding-left: 0px;
        margin-bottom: 15px;
    }

    .splashtext {
        float: none;
        text-align: center;
        padding-left: 0px;
    }

    #progressbar {
        margin-top: 20px;
    }

    .progressbar-text {
        right: 0px !important;
    }
}

@media only screen and (max-width: 498px) {
    .contentheader h1 {
        float: none;
    }
}

@media only screen and (max-width: 468px) {
    .submitbtn {
        font-size: 18px;
    }
}

@media only screen and (max-width: 414px) {
    .contentdiv {
        padding: 0;
    }
}

#registerForm .inputdiv {
    margin-top: 10px;
}

.hidden-input-enter {
    opacity: 0;
}

.hidden-input-enter-active {
    opacity: 1;
    transition: opacity 1500ms;
}

.hidden-input-exit {
    opacity: 1;
}

.hidden-input-exit-active {
    opacity: 0;
    transition: opacity 1500ms;
}


.hidden-validator-item-enter {
    opacity: 1;
}

.hidden-validator-item-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.hidden-validator-item-exit {
    opacity: 1;
    margin-left: 0;
    -webkit-transition: margin .2s ease-out 1s, max-height .2s ease-out 1.1s, opacity .2s ease-out 1s;
    -o-transition: margin .2s ease-out 1s, max-height .2s ease-out 1.1s, opacity .2s ease-out 1s;
    transition: margin .2s ease-out 1s, opacity .2s ease-out 1s;
}

.hidden-validator-item-exit-done {
    opacity: 0;
    max-height: 0px;
    margin-left: -25rem;
}

.hidden-validator-item-exit-active {
    opacity: 0;
    max-height: 5rem;
    margin-left: -25rem;
}


.hidden-validator-list-enter {
    opacity: 1;

}

.hidden-validator-list-enter-active {
    opacity: 1;
    transition: opacity 1500ms;
    display: block;
}

.hidden-validator-list-exit {
    opacity: 1;
}

.hidden-validator-list-exit-active {
    opacity: 1;
    transition: opacity 1000ms, display 1000ms;
}

.hidden-validator-list-exit-done {
    opacity: 0;
    display: none;
}

.error-container-error, .error-container-error input {
    background-color: #F2DEDE;
}

.error-container-ok, .error-container-ok input {
    background-color: #DFF0D8;
}

.head {
    color: #ba5915;
    text-decoration: underline;
}

.subhead {
    text-decoration: underline;
}

.faqItem-enter {
    opacity: 0;
    max-height: 0px;
}

.faqItem-enter-active {
    opacity: 1;
    max-height: 50rem;
    transition: opacity 500ms, max-height 500ms;
}

.faqItem-exit {
    opacity: 1;
}

.faqItem-exit-done {
    opacity: 0;
    height: 0px;
    display: none;
}

li.faqItem-exit-active {
    opacity: 0;
    max-height: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    transition: opacity 500ms linear, max-height 500ms, margin-top 500ms, margin-bottom 500ms, padding-top 500ms, padding-bottom 500ms, overflow 0s;
}

li.faqText {
    background: #dedede;
    color: #000;
}

.faqItem-enter {
    opacity: 0;
    max-height: 0px;
}

.faqItem-enter-active {
    opacity: 1;
    max-height: 50rem;
    transition: opacity 500ms, max-height 500ms;
}

.faqItem-exit {
    opacity: 1;
}

.faqItem-exit-done {
    opacity: 0;
    height: 0px;
    display: none;
}

li.faqItem-exit-active {
    opacity: 0;
    max-height: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    transition: opacity 500ms linear, max-height 500ms, margin-top 500ms, margin-bottom 500ms, padding-top 500ms, padding-bottom 500ms, overflow 0s;
}

li.faqText {
    background: #dedede;
    color: #000;
}

.faq li.q {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 95%;
    border: 1px #ddd solid;
    cursor: pointer;
}

.faq li.a {
    background: #dedede;
    display: none;
    color: #000;
}

.faq li.a a {
    color: #5db75d;
    font-weight: 600;
    text-decoration: underline;
}

.faq li {
    padding: 15px;
    text-align: left;
    list-style: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #d6d6d6;
    margin: 20px 0;
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

ul.faq {
    padding: 0;
    margin: 0 auto 1rem;
}


footer a {
    color: #fff;
}
