@charset "utf-8";
/* CSS Document */

* {
    margin: 0;
    padding: 0;
}

.blur {
    filter: blur(6px);
    -webkit-filter: blur(6px);
}

.main-contact-container .pzeropad {
    padding: 15px 0 0;
    margin: 0 0 -12px 0;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 11px;
    right: 7%;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #efe711;
    color: white;
    cursor: pointer;
    padding: 12px 17px;
    border-radius: 60px;
}

#myBtn .fa {
    font-size: 22px;
    color: #000;
}

#myBtn:hover {
    background-color: #bcb60e;
}

/*=====*/

.menu-container {
    /*background: #99b2cc;*/
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
}

.menu-container nav.navbar.navbar-expand-lg {
    padding: 20px 0;
}

.menu-container .desh {
    padding: 9px 6px;
    color: #fff;
}

.menu-container button {
    border: 1px solid;
    background: #23476b;
    font-family: Libre Franklin;font-weight: 400;
    font-size: 15px;
}

.menu-container button:hover,
.menu-container button:active,
.menu-container button:visited {
    background: #23476b;
    border-color: #23476b;
}

.menu-container button.mr-2,
.menu-container button.mr-2:hover,
.menu-container button.mr-2:active {
    background: #efe711;
}


.menu-container button i.fa.fa-paper-plane {
    background: #efe711;
    padding: 6px;
    color: #000;
    border-bottom: 2px solid #bcb60e;
    border-radius: 3px;
    margin: 0 0 0 10px;
}

.menu-container a {
    color: #fff !important;
    font-family: Libre Franklin;font-weight: 400;
}

.menu-container a.login {
    color: #efe711 !important;
}

.menu-container a:hover {
    color: #fff;
}

.btn-success {
    background-color: #336699;
    border-color: #336699;
}

/* =====  */

.inner-container {
    background: url("/images/banner-new.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-container {
    text-align: center;
    padding: 150px 0 50px 0;
    color: #fff;
}

.header-title {
    color: #fff;
}

.header-container.hero {
    padding: 200px 0;
}

.header-container a {
    background: #efe711;
    padding: 20px 45px;
    color: #000;
    border-bottom: 2px solid #bcb60e;
    border-radius: 3px;
    font-family: Libre Franklin;font-weight: 400;
    font-size: 1.2em;
}

.header-container span, .header-title span {
    color: #efe711;
}

.header-container h6 {
    padding-bottom: 15px;
    font-family: Libre Franklin;font-weight: 600;;
}

.header-container h5 {
    padding-top: 20px;
    font-family: Libre Franklin;font-weight: 600;;

}

.header-container h5 i.fa.fa-arrow-down {
    color: #336699;
}

.header-container h5 small {
    font-family: Libre Franklin;font-weight: 400;
}

.header-container h1 {
    padding-bottom: 15px;
    font-family: Libre Franklin;font-weight: 800;
    color: #fff;
}

.header-container p {
    padding-bottom: 15px;
    font-family: Libre Franklin;font-weight: 400;
}

/* ===== */

.sec-2 {
    background: url("/images/sec-banner.jpg");
    background-size: 100%;
}

.inner-header-wrapper {
    padding: 6% 0 6% 0;
}

.inner-header-wrapper hr {
    width: 5%;
    background: #336699;
}

.inner-header-wrapper h2 {
    font-family: Libre Franklin;font-weight: 600;;
}

.inner-header-wrapper p {
    font-family: Libre Franklin;font-weight: 400;
}

.inner-header-wrapper h6 {
    color: #336699;
    font-family: Libre Franklin;font-weight: 600;;
}

/* ===== */

a.btn.btn-default:hover {
    color: #336699;
}

/* ===== */

.faqs-container {
    background: url("/images/thr-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
}

.faqs-container h2 {
    margin-top: 30px;
}

.faqs-container .faq {
    text-align: center;
    color: #fff;
    padding: 30px 80px 30px 10px;
    font-family: Libre Franklin;font-weight: 400;
}

.faqs-container .faq-1 {
    text-align: center;
    color: #fff;
    padding: 30px 10px 30px 80px;
    font-family: Libre Franklin;font-weight: 400;
}

.faqs-container hr {
    width: 9.8%;
    background: #336699 /*#bfb814*/;
}

/* ======= */

.footer-widget {
    background-color: #c1d1e0;
    padding: 70px 0;
    text-align: center;
}

.footer-widget h1 {
    /* float: left; */
    padding-left: 15px;
    font-family: Libre Franklin;font-weight: 600;;
    font-size: 33px;
}

.footer-widget small {
    font-size: 44%;
    position: relative;
    bottom: 8px;
    font-family: Libre Franklin;font-weight: 400;
}

.footer-widget a {
    background: #efe711;
    padding: 20px 45px !important;
    color: #000;
    border-bottom: 2px solid #bcb60e !important;
    border-radius: 5px !important;
    margin-left: 15px;
    font-family: Libre Franklin;font-weight: 400;
    font-size: 1.2em;
}

footer {
    background-color: #23476b;
    color: #fff;
    padding: 25px 0;
}

footer nav {
    border-bottom: 2px dotted #5b84ad;
}

footer nav a img {
    width: 90%;
}

footer li {
    list-style-type: none;
    color: #fff;
    font-family: Libre Franklin;font-weight: 400;
}

footer li a:hover {
    color: #efe711;
}

footer a.nav-link {
    padding: 0px 5px;
    font-size: 16px;
    color: #fff;
    font-family: Libre Franklin;font-weight: 400;
}

footer li .desh {

}

footer h5 {
    color: #fff;
}

footer h6 {
    font-size: 14px;
    padding-top: 20px;
    color: #fff;
    font-family: Libre Franklin;font-weight: 400;
}

footer p {
    font-size: 12px;
    padding-top: 20px;
    color: #fff;
    font-family: Libre Franklin;font-weight: 400;
}

footer p span {
    color: #ffffff;
    font-family: Libre Franklin;font-weight: 400;

}

.ssl {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;
}

.login {
    color: #efe711 !important;
}

.hidden {
    display: none !important;
}

/*  ===========contact page style   */


.main-contact-container {
    background: url("/images/banner-new.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
    padding: 120px 0 70px;
}

.main-contact-container form {
    /*padding: 0 10% 6%;*/
}

.main-contact-container .form-control {
    line-height: 2;
}

/*.main-contact-container label {
    padding-top: 2%;
    padding-bottom: 2%;
    color: #2d5b89;
    font-family: Libre Franklin;font-weight: 600;;
}
 .main-contact-container p{
padding: 3% 0;
font-family: Libre Franklin;font-weight: 400;
 }*/

.main-contact-container a {
    /*background: #efe711;
    padding: 10px 30px !important;
    color: #000;
    border-bottom: 2px solid #bcb60e !important;
    border-radius: 5px !important;
    margin-left: 15px;*/
    font-family: Libre Franklin;font-weight: 400;
}


/*  ========== server page style*/
.main-server-container {
    background: url("/images/server-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.main-server-container h5 {
    padding: 6% 0 20px;
}

.main-server-container .heading-connect {
    background: #fff;
    width: 25%;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 2px #cde3cd;
    font-family: Libre Franklin;font-weight: 800;
}

.main-server-container .inner-cont {
    padding: 3% 0;
}

.heading-connect h2 {
    padding: 6px 0;
    color: #2d5b89;
}

.main-server-container .circle {
    padding: 4% 44px 0 0;
}

.circle img {
    position: relative;
    left: 17%;
    bottom: 44px;
}

.main-server-container h1 {
    font-family: Libre Franklin;font-weight: 600;;
    padding: 3% 0 2%;
}

.main-server-container hr {
    background: #2d5b89;
    width: 4%;
    margin: auto;
}

.main-server-container h6 {
    font-family: Libre Franklin;font-weight: 400;
    padding: 3% 0 0;
    line-height: 2
}

.main-server-container h6 a {
    color: #2d5b89
}

/*================*/


body.modal-open .background-container {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    filter: url("https://gist.githubusercontent.com/amitabhaghosh197/b7865b409e835b5a43b5/raw/1a255b551091924971e7dee8935fd38a7fdf7311/blur" .svg#blur);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}

.popUp-Container {

}

.popUp-Container h1 {
    padding: 19px 48px 0;
    font-family: Libre Franklin;font-weight: 800;
}

.popUp-Container h6 {
    color: #a4cea5;
    padding: 0;
    font-family: Libre Franklin;font-weight: 400;
}

.popUp-Container .modal-content {
    width: 80% !important;
    background: #2d5b89;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #bbcfbb;
    border: none;
}

.popUp-Container div#myModal {
    padding-left: 8%;
    display: block;
    width: 610px;
    text-align: center;
    top: 12%;
    margin: 0 auto;
}

.popUp-Container .modal-content .modal-body .txt {
    background: #99b2cc;
    border-radius: 35px;
}

.txt p {
    padding: 10px 0;
    font-family: Libre Franklin;font-weight: 400;
}

.popUp-Container .modal-content .modal-footer {
    background: url("/images/pop-bg.png");
    border: none;
    background-repeat: no-repeat;
}

.popUp-Container .detail {
    padding: 11% 0 0;
    background: url("/images/pop-bg.png");
    border: none;
    background-repeat: no-repeat;
}

.popUp-Container .detail h6 {
    color: #000;
    font-family: Libre Franklin;font-weight: 400;
    padding-top: 3px;
    padding-bottom: 11px;
}

.popUp-Container .detail a {
    background: #efe711;
    padding: 10px 30px !important;
    color: #000;
    border-bottom: 2px solid #bcb60e !important;
    border-radius: 5px !important;
    margin-left: 15px;
    font-family: Libre Franklin;font-weight: 400;
}

.popUp-Container .detail p {
    color: #000;
    font-family: Libre Franklin;font-weight: 400;
    padding-top: 5%;
}


/*============================  Contact Us Page2*/
.contact-form-wrapper textarea {
    width: 100%;
    height: 130px;
    border-radius: 5px;
}

.contact-form-wrapper input[type=submit] {
    background: #efe711;
    margin-top: 33px;
    padding: 10px 0;
    color: #060606;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 600;
}

.address-map-container {
    background: url("/images/cont-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.address-map-container .cntct-detail {
    padding: 75px 0;
    text-align: center;
}

.cntct-detail h6 {
    font-family: Libre Franklin;font-weight: 600;;
}

.cntct-detail h1 {
    font-family: Libre Franklin;font-weight: 800;
    color: #2d5b89;
}

.cntct-detail h5 {
    font-family: Libre Franklin;font-weight: 400;
}

.cntct-detail p {
    font-size: 14px;
}

.cntct-detail span {
    color: #2d5b89;
}

.cntct-detail h5 i.fa.fa-arrow-down {
    color: #2d5b89;
}

.address-map-container img {
    float: right;
    padding-top: 6%;

}

.contact-form-wrapper {
    background-color: #23476b;
    padding: 25px 0 25px 0;
}

.contact-form-wrapper label {
    color: #fff;
}

.dp-form-wrap {
    padding: 10px 15px 10px 10px;
    background: #fff;
    border-radius: 15px;
}

.faq-page {
    padding: 50px 0;
}

.faq-page h2 {
    text-align: center;
    font-size: 48px;
    font-weight: 600;
}

.faq-page hr.botom {
    border-top: 1px solid #23476b;
    width: 55px;
    margin: 30px auto;
}

.faq li.q {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 95%;
    border: 1px #ddd solid;
    cursor: pointer;
}

.faq li.a {
    background: #dedede;
    display: none;
    color: #000;
}

.faq li.a a {
    color: #336699;
    font-weight: 600;
    text-decoration: underline;
}

.faq li {
    padding: 15px;
    text-align: left;
    list-style: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #d6d6d6;
    margin: 20px 0;
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

ul.faq {
    padding: 0;
    margin: 0 0 1rem;
    width: 100%;
}

.cont-detail .regpge {
    width: 50%;
}

a .btn .btn-default:hover {
    color: #336699;
}

/* responsive */

@media only screen and (max-width: 1024px) {

    .pull-right {
        max-width: 100%;
    }

    .footer-widget {
        padding: 40px 0;
    }

    .footer-widget h1 {
        padding-left: 0;
    }

    textarea {
        width: 100%;
    }

    .address-map-container img {
        max-width: 100%;
    }

    .address-map-container .cntct-detail {
        padding: 25px 0;
    }

    .popUp-Container .detail {
        padding: 13% 0 0;
        background-size: cover;
    }
}

@media only screen and (max-width: 954px) {
    .menu-container {
        background-color: rgb(35, 71, 107);
    }

    .faqs-container .faq {
        padding: 30px 0px 30px 0px;
    }

    .faqs-container .faq-1 {
        padding: 30px 0px 30px 0px;
    }
}

@media only screen and (max-width: 768px) {

    .menu-container .desh {
        display: none;
    }

    .cont-detail .heading-bg {
        width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .ml-auto, .mr-auto {
        margin: auto;
    }

    .header-container {
        padding: 90px 0 45;
    }

    .header-container.hero {
        padding: 180px 0 45px 0;
    }

    .header-container h1 {
        font-size: 30px;
        margin-bottom: 0;
    }

    .header-container p {
        margin-bottom: 0;
        font-size: 12px;
    }

    .inner-header-wrapper h2, .faqs-container .faq-1 h2 {
        font-size: 26px;
    }

    .faqs-container .faq h2 img, .faqs-container .faq-1 h2 img {
        width: 45px;
        margin: 0 12px 0 0;
        float: left;
    }

    .faqs-container .faq h2 {
        font-size: 21px;
        line-height: 1;
    }

    .faqs-container .faq {
        padding: 15px;
    }

    .faqs-container .faq-1 {
        padding: 15px;
    }

    .faq-page {
        padding: 30px 0 20px 0;
    }

    .faq-page ol,
    .faq-page ul {
        padding-left: 15px;
    }

    .footer-widget {
        padding: 30px 0;
        text-align: center;
    }

    .footer-widget h1 {
        float: none;
        padding-left: 0;
        font-size: 25px;
    }

    .footer-widget small {
        font-size: 13px;
        bottom: 0;
        display: block;
    }

    footer {
        text-align: center;
    }

    footer p {
        font-size: 14px;
        padding-top: 0;
    }

    footer li {
        width: 50%;
        text-align: left;
    }

    footer li.desh {
        display: none;
    }

    footer a.nav-link {
        font-size: 13px;
    }

    .navbar .nav-link {
        padding: 15px;
        font-size: 105%;
    }

    #myBtn {
        bottom: 10px;
        right: 2%;
        padding: 7px 12px;
    }

    .cont-detail .heading-bg {
        width: 100%;
    }

    .cont-detail .heading-bg h2 {
        font-size: 27px;
        margin: 0
    }

    .main-contact-container .form-control {
        margin: 0 0 10px 0;
    }

    textarea {
        width: 100%;
    }

    .address-map-container .cntct-detail {
        padding: 40px 0 0;
    }

    .popUp-Container .modal-content {
        width: 100% !important;
    }

    .popUp-Container div#myModal {
        padding-left: 0;
        width: 98%;
        top: 2%;
    }

    .popUp-Container h1 {
        padding: 14px 0 0;
    }

    .popUp-Container .detail {
        padding: 0 0 0;
        background-size: cover;

    }

    .txt p {
        padding: 2px 0;
    }

    .popUp-Container .detail h6 {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: -20px;
    }

    .forgotpass .emil {

        width: 100%;
    }


}

@media only screen and (max-width: 414px) {
    .popUp-Container div#myModal {
        padding-left: 0;
        width: 98%;
        top: 11%;
    }

    .popUp-Container .detail h6 {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0px;
    }

    .popUp-Container .detail {

        padding: 45px 0 0;
        background-size: cover;
    }

    .navbar .nav-link {
        padding: 10px;
        font-size: 110%;
    }
}

.parsley-errors-list > li {
    display: none;
}

.parsley-errors-list > li:first-child {
    display: block;
}

.pos-rel {
    position: relative;
    padding-left: 35px;
}

.bs-callout-warning p.err {
    color: #B94A48;
}

.hint {
    color: #5c5c5c;
    margin-left: 5px;
    font-size: 14px;
    text-align: center;
}
